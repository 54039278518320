import React, { useState } from "react";
import { useAuth0 } from "./react-auth0-spa";
import ActionBar from "./ActionBar.style";
import Search from "./Search";
import { RouteComponentProps, withRouter } from "react-router";

export default withRouter(function({ match }: RouteComponentProps) {
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
  const [isMenuOpen, toggleMenu] = useState(false);
  return (
    <ActionBar.Container>
      {!isAuthenticated && (
        <ActionBar.LoginButton onClick={() => loginWithRedirect({})}>
          Log in
        </ActionBar.LoginButton>
      )}

      {isAuthenticated && (
        <>
          {!match.isExact && <Search size="small" />}
          {isMenuOpen && (
            <ActionBar.MenuList>
              <ActionBar.MenuItem
                onClick={() =>
                  logout({
                    returnTo: process.env.REACT_APP_URL
                  })
                }
              >
                Log out
              </ActionBar.MenuItem>
            </ActionBar.MenuList>
          )}
          <ActionBar.Button onClick={() => toggleMenu(!isMenuOpen)}>
            <ActionBar.Div1>
              <ActionBar.Div2>
                <ActionBar.Div3>
                  <ActionBar.Img src={user.picture} />
                </ActionBar.Div3>
              </ActionBar.Div2>
            </ActionBar.Div1>
          </ActionBar.Button>
        </>
      )}
    </ActionBar.Container>
  );
});
