//@ts-ignore
import mustache from "mustache";
const BANKRUPTCY_PREFIX = "http://data.dowjones.ai";
function formatURI(value: string) {
  return `<${BANKRUPTCY_PREFIX}${value}>`;
}
function parametrizeQuery(
  query: string,
  values: { value: string; type: string; id: string }[]
): string {
  const formattedValues = values.reduce(
    (acc: { [key: string]: string }, curr: any) => {
      const value =
        curr.type === "URI"
          ? formatURI(new URL(curr.value).pathname)
          : curr.value;
      acc[curr.id] = value;
      return acc;
    },
    {}
  );
  return mustache.render(query, formattedValues);
}

export { parametrizeQuery, BANKRUPTCY_PREFIX };
