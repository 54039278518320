import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;

  > div:first-child {
    margin: 0;
  }
`;

const Img = styled.img`
  vertical-align: top !important;
  box-sizing: border-box;
  width: 100%;
`;

const Button = styled.button`
  cursor: pointer;
  border: medium none !important;
  line-height: 64px !important;
  margin: 0px !important;
  position: relative !important;
  padding: 0px 8px !important;
  white-space: nowrap !important;
  transition: color 0.25s ease 0s !important;
  color: rgb(255, 255, 255) !important;
  height: 64px !important;
  text-decoration: none !important;
  display: inline-block !important;
  background: transparent none repeat scroll 0% 0% !important;
`;

const Div1 = styled.div`
  height: 100% !important;
  vertical-align: middle !important;
  box-sizing: border-box !important;
  border-bottom: 2px solid transparent !important;
`;

const Div2 = styled.div`
  display: inline-block !important;
  vertical-align: middle !important; /*! line-height: 1 !important; */
  border-bottom: 2px solid transparent !important; /*! display: none; */
`;

const Div3 = styled.div`
  background-color: rgb(242, 242, 242) !important;
  box-shadow: rgb(235, 235, 235) 0px 0px 0px 2px !important;
  border-radius: 50% !important;
  display: inline-block !important;
  vertical-align: middle !important;
  width: 48px !important;
  height: 48px !important;
  overflow: hidden !important; /*! box-sizing: none; */
`;

const MenuList = styled.ul`
  position: absolute;
  top: 6em;
  list-style: none;
  right: 2em;
  background-color: white;
  padding: 0.5em 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
`;

const MenuItem = styled.li`
  padding: 0.5em 1em;
  cursor: pointer;

  :hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const LoginButton = styled.a`
  text-decoration: underline;
  font-weight: bold;
  color: white;
  cursor: pointer;
`;

const ActionBar = {
  Container,
  Img,
  Button,
  Div1,
  Div2,
  Div3,
  MenuList,
  MenuItem,
  LoginButton
};

export default ActionBar;
