import _ from "lodash";
import React, { useState, useEffect } from "react";
import Search from "./Search.style";
import { SearchProps, SearchResultProps } from "semantic-ui-react";
import ApiClient from "./ApiClient";
import { useAuth0 } from "./react-auth0-spa";
import { fuzzy_search_of_main_categories } from "cq-queries/queries.json";
import { useRouter } from "./hooks";
import { parametrizeQuery } from "./queries";
import formatUtils from "./formatUtils";
const { getValueFromType } = formatUtils;
const MIN_SEARCH_CHARACTERS = 3;
const CASE_IDENTIFIER_TYPE =
  "http://data.dowjones.ai/ontologies/bankruptcy#CaseIdentifier";

type result = {
  childKey: number;
  title: string;
  uri: string;
  type: string;
  companyName?: string;
  caseNumber?: string;
  bkr: string;
  parent?: string;
  parentLabel?: string;
  parentCaseNumber?: string;
};

type state = {
  isLoading: boolean;
  results: result[];
  value: string;
};

type rdfBinding = {
  value: string;
  type: string;
};
type searchObj = {
  s: rdfBinding;
  o: rdfBinding;
  cls: rdfBinding;
  company_name?: rdfBinding;
  case_number?: rdfBinding;
  bkr: rdfBinding;
  parent?: rdfBinding;
  parent_label?: rdfBinding;
  parent_case_number?: rdfBinding;
};

const initialState: state = { isLoading: false, results: [], value: "" };

const formatResult = (response: { results: { bindings: any } }) => {
  try {
    const bindings = response.results.bindings;
    const bindingsMap = new Map();
    // Entries could be repeated however only if they have the same type should they be deduped
    // Also entries should be in the result type format, thus the transform from searchObj -> result
    // Formatting and deduping is being done in the same pass with the tradeoff of the extra space with the Map
    const result: result[] = bindings.reduce(
      (acc: result[], curr: searchObj, index: number) => {
        // Using the entry key ensures only same entries are getting deduped
        const {
          s,
          cls,
          o,
          bkr,
          case_number,
          company_name,
          parent,
          parent_label,
          parent_case_number
        } = curr;
        const key = s.value;
        const type = cls.value;

        if (!bindingsMap.get(key)) {
          bindingsMap.set(key, curr);
          acc.push({
            childKey: index,
            uri: type === CASE_IDENTIFIER_TYPE ? bkr.value : s.value,
            title: o.value,
            type: cls.value,
            companyName: company_name?.value,
            caseNumber: case_number?.value,
            bkr: bkr?.value,
            parent: parent?.value,
            parentLabel: parent_label?.value,
            parentCaseNumber: parent_case_number?.value
          });
        }
        return acc;
      },
      []
    );

    return result;
  } catch (error) {
    return [];
  }
};

const resultRenderer = (props: SearchResultProps) => {
  const {
    type,
    title,
    caseNumber,
    companyName,
    parentLabel,
    parentCaseNumber
  } = props;
  //TODO: Define types to map to 2 options only and avoid the ternary
  const isAffiliate = parentLabel !== companyName;
  const searchType =
    getValueFromType(type) === "Person" ? "Person" : "Commercial Organization";
  return (
    <div>
      <Search.ResultTitle>
        {companyName || title}
        {caseNumber && searchType !== "Person" && <span> - {caseNumber}</span>}
      </Search.ResultTitle>
      <Search.ResultDescription>
        <div>
          {isAffiliate
            ? `In joint administration under ${parentLabel} ${parentCaseNumber}`
            : searchType}
        </div>
      </Search.ResultDescription>
    </div>
  );
};

export default (props: SearchProps) => {
  const [state, setState] = useState(initialState);
  const { navigate } = useRouter();
  const { getIdTokenClaims } = useAuth0();

  const { isLoading, value, results } = state;

  function handleSearchChange(e: any, { value }: any) {
    setState(prevState => {
      return { ...prevState, value };
    });
  }

  useEffect(() => {
    if (value.length <= MIN_SEARCH_CHARACTERS)
      return setState({ ...initialState, value });
    setState(prevState => {
      return { ...prevState, isLoading: true, value };
    });
    const controller = new AbortController();
    async function fetchData() {
      const token = await getIdTokenClaims();
      const queryParams = {
        query: parametrizeQuery(fuzzy_search_of_main_categories, [
          { id: "param_str_query_text", value, type: "value" }
        ])
      };
      ApiClient.get("/", queryParams, {
        headers: { Authorization: `Bearer ${token.__raw}` },
        signal: controller.signal
      })
        .then(async results => {
          const formattedResults = formatResult(await results.json());
          setState(prevState => {
            return {
              ...prevState,
              isLoading: false,
              results: formattedResults
            };
          });
        })
        .catch(() => {});
    }
    fetchData();
    return () => {
      controller.abort();
    };
  }, [value]);

  return (
    <Search.Bar
      size="massive"
      fluid
      loading={isLoading}
      onSearchChange={_.debounce(handleSearchChange, 500, {
        leading: true
      })}
      minCharacters={MIN_SEARCH_CHARACTERS}
      onResultSelect={(e: any, data: { result: result }) => {
        const { uri, companyName, parent, parentLabel } = data.result;
        const isAffiliate = parentLabel !== companyName;
        const url = isAffiliate ? parent : uri;
        navigate(new URL(`${url}`).pathname);
      }}
      results={results}
      resultRenderer={resultRenderer}
      value={value}
      {...props}
    />
  );
};
