import _ from "lodash";

function getValueFromType(value: string) {
  return value.split("/").pop() || value;
}

function prettyRenderUri(value: string) {
  return _.startCase(getValueFromType(value)) || value;
}

function getValueFromUri(value: string) {
  return value.split("#").pop() || value;
}

export default { getValueFromType, prettyRenderUri, getValueFromUri };
