import styled from "styled-components";

const List = styled.ul`
  overflow-y: scroll;
  list-style: none;
  flex-basis: 500px;
  flex-grow: 1;
  padding: 0;
`;

const Item = styled.li`
  display: flex;
  margin-bottom: 3em;
`;

const Image = styled.img`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 138px;
  height: 95px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: inherit;
`;

const Info = styled.div`
  margin-left: 1em;
  display: flex;
  flex-direction: column;

  > span {
    margin-bottom: 0.25em;
  }
`;

const Headline = styled.a`
  font-weight: bold;
  margin-right: 1em;
`;

const SubLine = styled.span`
  color: ${props => props.theme.fontColors.secondary};
`;

const Article = {
  List,
  Item,
  Headline,
  Image,
  SubLine,
  Info
};

export default Article;
