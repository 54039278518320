import { DefaultTheme } from "styled-components";

const mainTheme: DefaultTheme = {
  borderRadius: "5px",
  colors: {
    main: "#394859",
    background: "#FFFFFF"
  },
  fontColors: {
    primary: "#110F24",
    secondary: "rgba(0, 0, 0, 0.5);"
  },
  fontFamilies: {
    banner: "escrow-banner, serif",
    heading: "escrow, serif",
    body: "lato"
  }
};

export default mainTheme;
