import React from "react";
import { RouteComponentProps } from "react-router";
//@ts-ignore
import { NetworkGraph } from "pulsar-components-library";
import Overview from "./Overview.stlye";
import TableWithDataFetch from "./TableWithDataFetch";
import NewsTable from "./NewsTable";
import { useDataApi } from "./hooks";
import { parametrizeQuery } from "./queries";
import {
  get_person_graph,
  what_are_other_cases_for_judge
} from "cq-queries/queries.json";
import { Link } from "react-router-dom";

type TParams = { itemID: string };
type rdfBinding = {
  value: string;
  type: string;
};

type rdfObj = {
  s: rdfBinding;
  o: rdfBinding;
  p: rdfBinding;
};
function formatNodesAndEdges(s: String) {
  return s.split(/[/#]/).pop();
}

// Get full human readable name from rdf to avoid a second call
function getFullName(bindings: rdfObj[]) {
  const names = bindings.reduce(
    (acc: { [key: string]: string }, curr: rdfObj) => {
      const value = curr.p.value.split("/").pop() || "null";
      acc[value] = curr.o.value;
      return acc;
    },
    { name: "missing name" }
  );
  return `${names["name"]}`;
}

const PERSON_OVERVIEW_COLUMN_DICT = {
  default: (columnName: string) => ({
    name: columnName,
    label: columnName.toUpperCase()
  }),
  is_closed: (columnName: string) => ({
    name: columnName,
    label: "Status",
    options: {
      customBodyRender: (value: string) => {
        return <span>{value === "is_closed" ? "Active" : "Closed"}</span>;
      }
    }
  }),
  case_number: (columnName: string) => ({
    name: columnName,
    label: "Case no.",
    options: {
      customBodyRender: (value: string, tableMeta: { rowData: any[] }) => {
        const path = new URL(tableMeta.rowData[0]).pathname;
        return <Link to={`${path}`}>{value}</Link>;
      }
    }
  }),
  case: (columnName: string) => ({
    name: columnName,
    label: columnName.toUpperCase(),
    options: {
      display: "excluded",
      filter: false
    }
  }),
  date: (columnName: string) => ({
    name: columnName,
    label: columnName.toUpperCase(),
    options: {
      // TODO: Do a better date parse check since Date.parse() is not 100% safe
      customBodyRender: (value: string) => {
        const date = Date.parse(value)
          ? new Date(value).toLocaleDateString()
          : null;
        return <time style={{ whiteSpace: "nowrap" }}>{date}</time>;
      }
    }
  })
};

const mockArticles = [
  {
    headline:
      "From WHYY and State Impact: Future owner of Philly refinery could be known as soon as January",
    date: "18 November 2019",
    source: "Delaware Business Daily",
    img:
      "https://whyy.org/wp-content/uploads/2019/08/2019-08-08_06__BS18543-B-Slabbers-Philadelphia-Philadelhia-Energy-Solutions-Refinery-PES-OEM-Update-—-BS1637-e1565312026458-768x534.jpg",
    url:
      "https://global.factiva.com/redir/default.aspx?P=sa&an=DELBUSD020191119efbi00003"
  },
  {
    headline:
      "Future owner of PES refinery could be decided as soon as January",
    date: "15 November 2019",
    source: "The Philadelphia Tribune",
    img:
      "https://www.witf.io/wp-content/uploads/2019/07/pes-union-president-1920x1280.jpg",
    url:
      "https://global.factiva.com/redir/default.aspx?P=sa&an=PHLTRB0020191115efbf0002q"
  },
  {
    headline:
      "BANKRUPTCY COURT | Judge gives city a spot at refinery auction; 15 potential biddersfile for PES complex",
    date: "15 November 2019",
    source: "The Philadelphia Inquirer",
    img:
      "https://www.inquirer.com/resizer/VhbNJu910pGtgjCrjZvfXP6Xx18=/1400x932/smart/arc-anglerfish-arc2-prod-pmn.s3.amazonaws.com/public/STHHPFOTAVGAPOR4UDAZ2EAG54.jpg",
    url:
      "https://global.factiva.com/redir/default.aspx?P=sa&an=PHLI000020191115efbf0000c"
  },
  {
    headline:
      "City and union officials to consult on Philadelphia refinery sale process -U.S.court",
    date: "14 November 2019",
    source: "Reuters News",
    img:
      "https://s4.reutersmedia.net/resources/r/?m=02&d=20190822&t=2&i=1421698493&r=LYNXNPEF7L22V&w=1200",
    url:
      "https://global.factiva.com/redir/default.aspx?P=sa&an=PHLTRB0020191115efbf0002q"
  },
  {
    headline:
      "Philadelphia Energy Lenders Battle Over Rights to $1.25 Billion Insurance; Juneexplosion closed the company's refinery, triggering coverage...",
    date: "8 August 2019",
    source: "WSJ Pro",
    img: "https://images.wsj.net/im-85085?width=1260&size=1.5",
    url:
      "https://global.factiva.com/redir/default.aspx?P=sa&an=RSTPROBK20190808ef88000dx"
  },
  {
    headline:
      "Trending From WHYY: Del. bankruptcy court judge approves $65 million loan for Phillyrefinery",
    date: "25 July 2019",
    source: "Delaware Business Daily",
    img:
      "https://delawarebusinessnow1.imgix.net/2019/07/PHL-Energy-Solutions-photo.png?fm=png&ixlib=php-1.2.1&s=1bf08377064f34d425c901951b8bb3ef",
    url:
      "https://global.factiva.com/redir/default.aspx?P=sa&an=DELBUSD020190726ef7p00002"
  },
  {
    headline:
      "U.S. bankruptcy judge approves $65 million loan for PES refinery",
    date: "24 July 2019",
    source: "The Philadelphia Tribune",
    img:
      "https://whyy.org/wp-content/uploads/2018/03/file-refinery-7-768x512.jpg",
    url:
      "https://global.factiva.com/redir/default.aspx?P=sa&an=PHLTRB0020190725ef7o00007"
  },
  {
    headline:
      "Oil Refinery Philadelphia Energy Files for Bankruptcy Protection; Largest oilrefiner on the East Coast said last month it would shut down...",
    date: "22 July 2019",
    source: "WSJ Pro Bankruptcy",
    img: "https://images.wsj.net/im-91754?width=1260&size=1.5",
    url:
      "https://global.factiva.com/redir/default.aspx?P=sa&an=RSTPROBK20190722ef7m0008d"
  }
];

export default function({ match }: RouteComponentProps<TParams>) {
  const networkGraphQuery = parametrizeQuery(get_person_graph, [
    { id: "param_uri_person_id", value: window.location.href, type: "URI" }
  ]);
  const [{ data, isLoading, error }] = useDataApi({
    query: networkGraphQuery
  });
  return (
    <>
      {isLoading ? (
        <span>Loading...</span>
      ) : error ? (
        <span>Error: {error.message}</span>
      ) : data ? (
        <Overview.MainContainer>
          <Overview.TitleContainer>
            <div>
              {data.results &&
                data.results.bindings.length > 0 &&
                getFullName(data.results.bindings)}
            </div>
          </Overview.TitleContainer>
          <Overview.Separator />
          <Overview.GridContainer>
            <div>
              <Overview.GridTitle>Case History</Overview.GridTitle>
              <Overview.FlexCell>
                <TableWithDataFetch
                  query={parametrizeQuery(what_are_other_cases_for_judge, [
                    {
                      id: "param_judge_iri",
                      value: window.location.href,
                      type: "URI"
                    }
                  ])}
                  columnDict={PERSON_OVERVIEW_COLUMN_DICT}
                />
              </Overview.FlexCell>
            </div>
            <Overview.FlexCell>
              <Overview.GridTitle>News</Overview.GridTitle>
              <NewsTable articles={mockArticles} />
            </Overview.FlexCell>
          </Overview.GridContainer>
          <Overview.Separator />
          <Overview.GridContainer>
            <Overview.FlexCell>
              <Overview.GridTitle>Knowledge Graph</Overview.GridTitle>
              <div style={{ height: "750px" }}>
                <NetworkGraph
                  triples={data}
                  formatNodes={formatNodesAndEdges}
                  formatEdges={formatNodesAndEdges}
                />
              </div>
            </Overview.FlexCell>
          </Overview.GridContainer>
        </Overview.MainContainer>
      ) : (
        <span>
          There were some problems fetching this case. Please try again later
        </span>
      )}
    </>
  );
}
