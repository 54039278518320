import React from "react";
import { Route, Switch } from "react-router-dom";
import App from "./App.style";
import Search from "./Search";
import CaseOverview from "./CaseOverview";
import PersonOverview from "./PersonOverview";
import ActionBar from "./ActionBar";
import { useAuth0 } from "./react-auth0-spa";

function OverviewLayout() {
  return (
    <Switch>
      <Route path="/entity/bkr/chapter11/:itemID" component={CaseOverview} />
      <Route path="/entity/bkr/person/:itemID" component={PersonOverview} />
    </Switch>
  );
}

export default () => {
  const { loading, isAuthenticated } = useAuth0();

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <div className="App">
      <App.Header>
        <App.Title to="/">WSJ Bankruptcy Intelligence</App.Title>
        <ActionBar />
      </App.Header>
      {isAuthenticated && (
        <App.Body>
          <Switch>
            <Route exact path="/" component={Search} />
            <Route path="/entity" component={OverviewLayout} />
          </Switch>
        </App.Body>
      )}
    </div>
  );
};
