import styled from "styled-components";
import { Search as SearchComponent } from "semantic-ui-react";

const Bar = styled(SearchComponent)`
  margin-top: 10%;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1em;
  /* Avoid massive search bar for big screens */
  > div:first-child {
    width: 100%;
    max-width: 1000px;
  }

  input:first-child {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

const ResultTitle = styled.div`
  color: ${props => props.theme.fontColors.primary};
`;

const ResultDescription = styled.div`
  font-size: 0.75em;
  font-weight: bold;
  color: ${props => props.theme.fontColors.secondary};
`;

const Search = {
  Bar,
  ResultTitle,
  ResultDescription
};

export default Search;
