import styled from "styled-components";
import { Link } from "react-router-dom";

const Header = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.main};
  display: flex;
  justify-content: space-between;
  padding: 2em;
  font-family: ${props => props.theme.fontFamilies.banner};
  min-height: 120px;
  line-height: normal;
`;

const Body = styled.div``;

const Footer = styled.div``;

const Title = styled(Link)`
  min-height: 1rem;
  font-size: 3em;
  color: white;
  margin: 0;
  :hover {
    color: white;
  }
`;

const App = {
  Header,
  Title,
  Body,
  Footer
};

export default App;
