const BASE_API_URL = process.env.REACT_APP_API_URL;

// TODO: Convert into a shorthand type
function formatQueryParams(
  paramsObject:
    | string
    | URLSearchParams
    | string[][]
    | Record<string, string>
    | undefined
) {
  const params = new URLSearchParams(paramsObject);
  return params.toString();
}

function get(
  path?: string,
  queryParamsObj?:
    | string
    | URLSearchParams
    | string[][]
    | Record<string, string>
    | undefined,
  params?: RequestInit
) {
  const url = `${BASE_API_URL}${path}`;
  const formattedUrl = queryParamsObj
    ? `${url}?${formatQueryParams(queryParamsObj)}`
    : url;
  return fetch(formattedUrl, {
    ...params
  });
}

const ApiClient = {
  get
};

export default ApiClient;
