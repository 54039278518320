import styled from "styled-components";

const Separator = styled.div`
  border: 1.5px solid #000000;
`;

const VerticalSeparator = styled.div`
  border: 2px solid #e4e4e4;
  width: 1px;
  border-radius: 5px;
  display: none;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Escrow;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 2em;
  font-family: ${props => props.theme.fontFamilies.heading};
`;

const MainContainer = styled.div`
  padding: 4em 2em;
`;

const GridContainer = styled.div`
  display: grid;
  width: 100%;
  grid-auto-flow: row;
  margin: 2em 0;
  > div:nth-child(2) {
    margin-top: 1em;
  }

  @media (min-width: 1440px) {
    grid-auto-flow: none;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));

    > div:nth-child(2) {
      margin-top: 0;
      border-left: 2px solid #e4e4e4;
      margin-left: 1em;
      padding-left: 1em;
    }
  }
`;

const FlexCell = styled.div`
  display: flex;
  flex-basis: 500px;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
`;

const GridHeading = styled.div`
  color: ${props => props.theme.fontColors.secondary};
  font-weight: bold;
`;

const GridValue = styled.div`
  color: ${props => props.theme.fontColors.primary};
  font-weight: bold;
  font-size: 1.16em;
`;

const GridTitle = styled.h2`
  font-family: ${props => props.theme.fontFamilies.heading};
`;

const Details = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  grid-column-gap: 1em;
  grid-row-gap: 2em;
  width: 100%;
  justify-content: space-between;
  margin: 2em 0;
`;

const Overview = {
  Separator,
  TitleContainer,
  MainContainer,
  GridContainer,
  GridHeading,
  GridValue,
  GridTitle,
  Details,
  VerticalSeparator,
  FlexCell
};

export default Overview;
