import React from "react";
import { useDataApi } from "./hooks";
//@ts-ignore
import { DataTable } from "pulsar-components-library";
const initialRdf = {
  head: {
    vars: []
  },
  results: {
    bindings: []
  }
};
function TableWithDataFetch(props: {
  query: string;
  reasoning?: boolean;
  columnDict?: any;
  options?: any;
}) {
  const [{ data, isLoading, error }] = useDataApi(
    {
      query: props.query,
      reasoning: props.reasoning
    },
    initialRdf
  );

  return (
    <>
      {isLoading ? (
        <span>Loading...</span>
      ) : error ? (
        <span>Error</span>
      ) : data?.results?.bindings ? (
        <DataTable
          rdfObj={data}
          options={{
            print: false,
            download: false,
            search: false,
            viewColumns: false,
            filter: false,
            responsive: "scrollMaxHeight",
            rowsPerPage: 5,
            rowsPerPageOptions: [5, 20, 50, 100],
            textLabels: {
              body: {
                noMatch: "No matches for records found"
              }
            },
            ...props.options
          }}
          columnDict={props.columnDict}
        />
      ) : (
        <span>There was an error populating this section</span>
      )}
    </>
  );
}

export default TableWithDataFetch;
