import { useState, useEffect, useReducer } from "react";
import { useAuth0 } from "./react-auth0-spa";
import ApiClient from "./ApiClient";
import { useLocation, useHistory } from "react-router-dom";

function useRouter() {
  const location = useLocation();
  const history = useHistory();

  function navigate(to: string, { replace = false } = {}) {
    if (replace) {
      history.replace(to);
    } else {
      history.push(to);
    }
  }

  return {
    location,
    navigate
  };
}

const dataFetchReducer = (
  state: any,
  action: { type: String; payload?: any }
) => {
  switch (action.type) {
    case "FETCH_INIT":
      console.log(action);
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case "FETCH_SUCCESS":
      console.log(action);
      return {
        ...state,
        isLoading: false,
        error: null,
        data: action.payload
      };
    case "FETCH_FAILURE":
      console.log(action);
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      throw new Error();
  }
};

function useDataApi(queryParams?: any, initialData?: any) {
  const [path, setPath] = useState("/");
  const { getIdTokenClaims } = useAuth0();

  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: false,
    error: null,
    data: initialData
  });

  useEffect(() => {
    let didCancel = false;
    const fetchData = async () => {
      dispatch({ type: "FETCH_INIT" });
      try {
        const token = await getIdTokenClaims();
        const result = await (
          await ApiClient.get(path, queryParams, {
            headers: { Authorization: `Bearer ${token.__raw}` }
          })
        ).json();

        if (!didCancel) {
          dispatch({ type: "FETCH_SUCCESS", payload: result });
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({ type: "FETCH_FAILURE", payload: error });
        }
      }
    };

    fetchData();

    return () => {
      didCancel = true;
    };
  }, [path, queryParams.query]);

  return [state, setPath];
}

export { useDataApi, useRouter };
