import React from "react";
import Article from "./NewsTable.style";

type IProps = {
  articles: article[];
};
type article = {
  img: string;
  source: string;
  headline: string;
  date: string;
  url: string;
};

function NewsTable({ articles }: IProps) {
  return (
    <Article.List>
      {articles.map((article: article) => {
        return (
          <Article.Item>
            <Article.Image src={article.img} />
            <Article.Info>
              <Article.SubLine>{article.source}</Article.SubLine>
              <Article.Headline
                href={article.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {article.headline}
              </Article.Headline>
              <Article.SubLine>{article.date}</Article.SubLine>
            </Article.Info>
          </Article.Item>
        );
      })}
    </Article.List>
  );
}

export default NewsTable;
