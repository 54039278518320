import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import "semantic-ui-css/semantic.min.css";
import { Auth0Provider } from "./react-auth0-spa";
import config from "./auth_config.json";
import mainTheme from "./mainTheme";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState: {
  targetUrl: string | null | undefined;
}) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

ReactDOM.render(
  <Auth0Provider
    domain={config.domain}
    client_id={config.clientId}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
  >
    <ThemeProvider theme={mainTheme}>
      <Router>
        <App />
      </Router>
    </ThemeProvider>
  </Auth0Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
